import React, {useContext} from "react";
import {IngestDocContext} from "../context/IngestDocument";


export const Uploader = () => {

    const {ingest, setIngest} = useContext(IngestDocContext)

    function attachFile(evt: any) {
        const file = evt.target.files[0]
        setIngest({...ingest, file})
    }

    return <div className="container">
        <div className="row">
            <div className="col-md-6">
                <form method="post" action="#" id="#">
                    <div className="form-group files color">
                        <label>Upload Your File </label>
                        <input type="file" className="form-control" onChange={attachFile}/>
                    </div>
                </form>
            </div>
        </div>
    </div>
}
