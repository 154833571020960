import {Grid} from 'semantic-ui-react'
import {IngestDocContext, IngestDocumentData, initIngestDocument} from '../context/IngestDocument';
import {useHistory, useParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {AssetDetails} from "./AssetDetails";
import {IngestEditForm} from "./IngestEditForm";
import {Uploader} from "./Uploader";
import config from "../config";
import axios from "axios";
import {AuthContext, checkAuth} from "../context/AuthContext";
import {ItemsPath} from "../RouterPath";
import {NewUploadStatus, UploadStatus} from "../context/UploadStatus";

interface RouteParams {
    id: string
}

export const IngestView = () => {
    const {auth, setAuth} = useContext(AuthContext);
    const history = useHistory();
    const params = useParams<RouteParams>()
    const [uploadStatus, setUploadStatus] = useState<UploadStatus>(NewUploadStatus);

    const gotoList = () => history.push(ItemsPath);

    function runUpload(response: any, file: any) {
        setUploadStatus({statusText: "Uploading a file...", inProgress: true});
        return axios
            .put(response.fileUrl, file, {headers: {"Content-Type": file.type}})
            .then(() => {
                setUploadStatus({statusText: "File uploaded. Updating metadata...", inProgress: true});
                console.log("File uploaded.")
            })
            .catch(function (error) {
                if (error.response) {
                    // Request made and server responded
                    console.log("Upload response: " + JSON.stringify(error.response.data));
                    console.log("Upload response status: " + error.response.status);
                } else if (error.request) {
                    // The request was made but no response was received
                    console.log("No response received for upload request.");
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error while uploading a file: ', error.message);
                }
                setUploadStatus({statusText: "Upload failed.", inProgress: false});
            })
            .then(() => response)
    }

    function getUploadUrl(id: string, fileName: string, fileSize: number) {
        console.info(`Registering file ${fileName} with size ${fileSize} for ingest ${id}`)
        const url = config.apiUrl + "/ingest/upload/register"
        const body = {id, fileName, fileSize}

        return axios
            .post(url, body, {headers: {Authorization: auth.token}})
            .then(res => res.data)
    }

    function isValid(): boolean {
        if (!ingest.file) {
            alert("Please select a file.")
            return false;
        }
        if (ingest.duration.adDurationInFrames == 0) {
            alert("Duration should be greater than '0'.")
            return false;
        }
        if (ingest.duration.firstActiveFrame + ingest.duration.adDurationInFrames > ingest.duration.fullDurationInFrames) {
            alert("Duration is invalid.")
            return false;
        }
        if (ingest.a5IngestData.status != "New") {
            alert(`Invalid asset status [${ingest.a5IngestData.status}] - expected 'New'`)
            return false;
        }
        else return true;
    }

    function runIngest() {
        if (isValid()) {
            setUploadStatus({statusText: "Registering...", inProgress: true});

            getUploadUrl(
                ingest.id,
                ingest.file? ingest.file.name : "",
                ingest.file ? ingest.file.size : -1
            ).then(response => {
                if (response.fileId) {
                    console.info(`Uploading file ${response.fileId} to ${response.fileUrl}`)
                    return runUpload(response, ingest.file)
                } else
                    throw new Error(`Upload failed: ${JSON.stringify(response)}`)
            }).then(response => {
                console.info("Updating A5 file " + response.fileId)
                return runAssetUpdate(response.fileId)
            }).then(() => gotoList())
              .catch(function (err) {
                  setUploadStatus({statusText: "Failed.", inProgress: false});
                  console.log(err);
                  alert("Upload failed!");
              })
        }
    }

    function runAssetUpdate(fileId: string) {
        const revision = {
            specDbDocID : ingest.videoFormat,
            name : ingest.file?.name,
            fileID : fileId,
            fileSize : ingest.file?.size,
            duration : ingest.duration,
            audioTracks: ingest.audioTracks
        }
        const closedCaptionStatus = ingest.closedCaptionStatus
        const asset = {
            ...ingest.a5IngestData.asset,
            revision,
            closedCaptionStatus
        }
        const metadata = {IngestBoxID: `WebIngest-${auth.tokenDigest}`}
        const updatedIngestDoc = {
            id: ingest.id,
            status: "TVC Ingested OK",
            asset,
            metadata
        }

        const url = config.apiUrl + `/ingest/update`
        return axios
            .put(url, updatedIngestDoc, {headers: {Authorization: auth.token}})
            .then(response => console.info("Ingest doc updated"))
    }

    // Create initial state and store within this component & context
    const initDoc = initIngestDocument(params.id)
    const [ingest, setIngest] = useState<IngestDocumentData>(initDoc)

    useEffect(() => {
        if (!checkAuth(auth)) return;
    }, [])

    return <IngestDocContext.Provider value={{ingest, setIngest}}>
        <Grid columns={2} centered>
            <Grid.Row>
                <Grid.Column className="with-padding">
                    <button onClick={gotoList}> &lt;&lt; Back to documents list</button>
                </Grid.Column>
                <Grid.Column/>
                <Grid.Column/>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column className="with-border">
                    <Uploader/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column className="with-border">
                    <AssetDetails/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column className="with-border">
                    <IngestEditForm/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column className="with-border">
                    <div> <h3> Status: </h3>  { uploadStatus.statusText } </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column style={{display: 'flex'}}>
                    <button style={{color: "green", marginLeft: "auto", fontSize: 18}} disabled={ uploadStatus.inProgress } onClick={runIngest}>[   SUBMIT   ]</button>
                </Grid.Column>
            </Grid.Row>

        </Grid>
    </IngestDocContext.Provider>
}