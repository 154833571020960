import React, {useContext, useEffect, useState} from "react";
import config from "../config"
import axios from 'axios';
import {Grid, Table} from 'semantic-ui-react'
import {useHistory} from "react-router-dom";
import {AuthContext, checkAuth, NoAuth, serverLogout} from "../context/AuthContext";
import {ItemById} from "../RouterPath";

export type Ingest = {
    key: string,
    clockNumber: string,
    orderReference: number,
    spec: string,
    agency: string
}

export type IngestList = {
    items: Array<Ingest>,
    page: number,
    clockNumber: string
}

const EmptyIngestList: IngestList = {items: [], page: 1, clockNumber: ""}

export const IngestListView = () => {
    const [state, setState] = useState(EmptyIngestList);
    const {auth, setAuth} = useContext(AuthContext);
    const history = useHistory();

    const transformIngest = (a5IngestResponse: any) => {
        return a5IngestResponse['data']['data'].map((i: any) => {
            return {
                key: i['_id'],
                clockNumber: i['asset']['unique'],
                orderReference: i['order']['reference'],
                spec: i['asset']['revision']['specDbDocID'],
                agency: i['agency']['name']
            }
        });
    }

    const showIngestItem = (id: string) => history.push(ItemById(id));
    const nextPage = () => setState({items: state.items, page: state.page + 1, clockNumber: state.clockNumber})
    const previousPage = () => setState({items: state.items, page: state.page > 1 ? state.page - 1 : 1, clockNumber: state.clockNumber})
    const firstPage = () => setState({items: state.items, page: 1, clockNumber: state.clockNumber})
    const logout = () => {
        setAuth(NoAuth);
        localStorage.removeItem("auth");
        return serverLogout();
    }

    function loadData() {
        const newQuery = `{"terms": {"status": ["New"]}}`
        const clockQuery = state.clockNumber === "" ?
            `query=${newQuery}` :
            `query={"and":[{"terms": {"asset.unique": ["${state.clockNumber}"]}}, ${newQuery}]}`
        const url = config.apiUrl + `/ingest/items?sort=_modified:desc&size=20&page=${state.page}&` + encodeURI(clockQuery)
        console.debug("Requesting ingests list from: " + url)
        axios.get(url, {headers: {Authorization: auth.token}})
            .then(transformIngest)
            .then((s) => setState({items: s, page: state.page, clockNumber: state.clockNumber}))
    }

    useEffect(() => {
        if (!checkAuth(auth)) return;
        loadData()
    }, [state.page, auth.tokenDigest])

    return auth.token ? <Grid columns={1} centered>
        <Grid.Row>
            <Grid.Column className="with-padding">
                <label className="with-padding">Clock number</label>
                <input
                    onKeyDown={e => {if (e.key === "Enter") loadData()}}
                    onChange={evt => setState({items: state.items, page: 1, clockNumber: evt.target.value})}
                    value={state.clockNumber}
                />
                <button onClick={loadData}> Filter </button>

                <button onClick={logout} style={{position: "absolute", right: "110px"}}> Logout </button>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <Table celled selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>[ Select ]</Table.HeaderCell>
                            <Table.HeaderCell>Clock Number</Table.HeaderCell>
                            <Table.HeaderCell>Order Reference</Table.HeaderCell>
                            <Table.HeaderCell>Agency</Table.HeaderCell>
                            <Table.HeaderCell>Spec</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            state.items.map((i: Ingest) =>
                                <Table.Row key={i.key}>
                                    <Table.Cell> <button onClick={() => showIngestItem(i.key)}> Select </button> </Table.Cell>
                                    <Table.Cell>{i.clockNumber}</Table.Cell>
                                    <Table.Cell>{i.orderReference}</Table.Cell>
                                    <Table.Cell>{i.agency}</Table.Cell>
                                    <Table.Cell>{i.spec}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </Grid.Column>
        </Grid.Row>
        <Grid.Row>
            <Grid.Column>
                <button onClick={() => firstPage()} > First </button>
                [ Page {state.page} ]
                <button onClick={() => previousPage()} > Previous </button> |
                <button onClick={() => nextPage()} > Next </button>
            </Grid.Column>
        </Grid.Row>
    </Grid> : <div> Loading... </div>
}